import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import Sidebar from "../../Components/SIdebar";
import { fetchBrokersLinks, postBrokers } from "../../api/Helper";

const BrokerLinks = () => {
  const [brokers, setBrokers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingIndex, EditingIndex] = useState(null);

  const handleUpdateLink = (event) => {
    let newBrokers = [...brokers];
    newBrokers[editingIndex].link = event.target.value;
    setBrokers([...newBrokers]);
  };

  function updateBrokers() {
    setLoading(true);
    fetchBrokersLinks()
      .then((brokers) => setBrokers(brokers))
      .catch(() => alert("Failed to get Data from Server"))
      .finally(() => setLoading(false));
  }

  const handleSave = () => {
    setLoading(true);
    postBrokers(brokers)
      .then(() => EditingIndex(null))
      .catch(() => alert("Failed to Update Links"))
      .finally(() => setLoading(false));
  };

  useEffect(() => updateBrokers(), []);

  return (
    <div className="flx">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="users-container">
        <h2>Manage Broker Links</h2>
        <table className="users-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Link</th>
              <th>Action</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody>
              {brokers.map((user, index) =>
                editingIndex === index ? (
                  <tr key={user.userId}>
                    <td>{user.name}</td>
                    <td>
                      <input value={user.link} onChange={handleUpdateLink} />
                    </td>
                    <td onClick={handleSave}>Save</td>
                  </tr>
                ) : (
                  <tr key={user.userId}>
                    <td>{user.name}</td>
                    <td>{user.link}</td>
                    <td onClick={() => EditingIndex(index)}>Edit</td>
                  </tr>
                )
              )}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default BrokerLinks;
