import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Users from './pages/Users';
import NotFound from './pages/NotFound';
import PrivateRoute from './Routes/PrivateRoute';
import BrokerLinks from './pages/BrokerLinks/BrokerLinks';
import ContactUs from './pages/ContactUs/ContactUs';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/users" element={<Users />} />
        <Route path="/broker_links" element={<BrokerLinks />} />
        <Route path="/contact_us" element={<ContactUs />} />
        <Route path="/" element={<PrivateRoute />}>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
