import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader/Loader";
import Sidebar from "../../Components/SIdebar";
import { fetchContactUsQueries } from "../../api/Helper";

const ContactUs = () => {
  const [enquiries, setEnquiries] = useState([]);
  const [loading, setLoading] = useState(false);

  function updateEnquiries() {
    setLoading(true);
    fetchContactUsQueries()
      .then((brokers) => setEnquiries(brokers))
      .catch(() => alert("Failed to get Data from Server"))
      .finally(() => setLoading(false));
  }

  useEffect(() => updateEnquiries(), []);

  return (
    <div className="flx">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="users-container">
        <h2>Contact Us Enquiries</h2>
        <table className="users-table">
          <thead>
            <tr>
              <th>Ticket</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Number</th>
              <th>Subject</th>
              <th>Message</th>
            </tr>
          </thead>
          {loading ? (
            <Loader />
          ) : (
            <tbody>
              {enquiries.map((enquiry) => (
                <tr key={enquiry.ticketId}>
                  <td>{enquiry.ticketId}</td>
                  <td>
                    {enquiry.firstName} {enquiry.middleName} {enquiry.lastName}
                  </td>
                  <td>{enquiry.email}</td>
                  <td>{enquiry.phoneNumber}</td>
                  <td>{enquiry.subject}</td>
                  <td>{enquiry.message}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default ContactUs;
