import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';

const Sidebar = () => {
    const navigate = useNavigate();
    const Logout=()=>{
        localStorage.removeItem('user'); 
        navigate('/login');
    }

  return (
    <div className="sidebar">
      <h2>Sidebar</h2>
      <ul>
        <li><Link to="/users">Users</Link></li>
        <li><Link to="/broker_links">Broker Links</Link></li>
        <li><Link to="/contact_us">Contact Us</Link></li>
      </ul>
      <h4 onClick={Logout}>Logout</h4>
    </div>
  );
};

export default Sidebar;
