import React, { useEffect, useState } from "react";
import { getUsers } from "../api/Helper";
import { formatDate } from "../Utilities/Methords";
import Sidebar from "../Components/SIdebar";
import Loader from "../Components/Loader/Loader";
import "./Users.css";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUsers().then((data) => {
      setLoading(false);
      setUsers(data);
    });
  }, []);

  return (
    <div className="flx">
      <div className="sidebar">
        <Sidebar />
      </div>
      <div className="users-container">
        <h2>Manage Users</h2>
        <table className="users-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Client ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile No.</th>
              <th>Status</th>
            </tr>
          </thead>
          {loading ? (
           <Loader />
          ) : (
            <tbody>
              {users.map((user) => (
                <tr key={user.userId}>
                  <td>{user.userId}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>{user.clientID}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.mobileNo}</td>
                  <td>Active</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default Users;
